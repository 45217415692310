<template>
  <div>
    <l-form-model layout="inline">
      <l-form-model-item label="素材分类:">
        <l-select
          v-model="puzzleTypeSelect"
          placeholder="请选择"
          @change="showList(1)"
        >
          <l-select-option
            v-for="(item) in puzzleTypeSelectArr"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </l-select-option>
        </l-select>
      </l-form-model-item>
      <!-- 新增颜色库按钮 -->
      <l-button
        type="default"
        class="c-colour-manage_add-buttom"
        @click="addColour()"
      >
        新增颜色库
      </l-button>
    </l-form-model>
    <!-- 列表展示区 -->
    <l-table-common
      :data-source="tableData"
      @change="showList"
    >
      <l-table-column
        data-index="index"
        title="序号"
      />
      <l-table-column
        data-index="nameZhcn"
        title="颜色库名字"
      />
      <l-table-column title="配色展示">
        <template slot-scope="scope">
          <div class="c-colour-manage_list-colour-show">
            <div class="c-colour-manage_list-colour-show-son" :style="{backgroundColor:scope.color1}"></div>
            <div class="c-colour-manage_list-colour-show-son" :style="{backgroundColor:scope.color2}"></div>
            <div class="c-colour-manage_list-colour-show-son" :style="{backgroundColor:scope.color3}"></div>
            <div class="c-colour-manage_list-colour-show-son" :style="{backgroundColor:scope.color4}"></div>
            <div class="c-colour-manage_list-colour-show-son" :style="{backgroundColor:scope.color5}"></div>
            <div class="c-colour-manage_list-colour-show-son" :style="{backgroundColor:scope.color6}"></div>
          </div>
        </template>
      </l-table-column>
      <l-table-column
        title="素材分类"
      >
        <template slot-scope="scope">
          <div>
            {{ puzzleTypeStr[scope.puzzleType + ''] || '' }}
          </div>
        </template>
      </l-table-column>
      <l-table-column title="顺序调整">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            v-if="$utils.isUp(scope.index, tableData)"
            class="c-colour-manage_mobile-buttom"
            @click="colourSort(scope.id, 1)"
          >
            上移
          </l-button>
          <l-button
            v-if="$utils.isDown(scope.index, tableData)"
            class="c-colour-manage_mobile-buttom"
            @click="colourSort(scope.id, 2)"
          >
            下移
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="详情">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="colourDetail(scope)"
          >
            <div>
              详情
            </div>
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="删除">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="colourRemove(scope)"
          >
            <div>
              删除
            </div>
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表展示结束区 -->

    <!-- 新增颜色库和颜色库详情弹窗 -->
    <div>
      <l-modal
        v-model="addColourButtom"
        title="颜色库信息"
        @ok="colourAddYes"
      >
        <div class="c-colour-manage_colour-bags">
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">素材分类 :</div>
            <l-radio-group
              v-model="puzzleType"
              class="c-colour-manage_colour-input"
              name="radioGroup"
            >
              <l-radio :value="1">
                拼图
              </l-radio>
              <l-radio :value="2">
                拼字
              </l-radio>
            </l-radio-group>
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色库名称 :</div>
            <l-input v-model="colourStore.nameZhcn" class="c-colour-manage_colour-input" placeholder="请输入" />
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">繁体名称 :</div>
            <l-input v-model="colourStore.nameZhtw" class="c-colour-manage_colour-input" placeholder="请输入" />
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">英文名称 :</div>
            <l-input v-model="colourStore.nameEn" class="c-colour-manage_colour-input" placeholder="请输入" />
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色一 :</div>
            <div class="c-colour-manage_colour-input"><l-color-picker v-model="colourStore.colourOne" /></div>
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色二 :</div>
            <div class="c-colour-manage_colour-input"><l-color-picker v-model="colourStore.colourTwo" /></div>
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色三 :</div>
            <div class="c-colour-manage_colour-input"><l-color-picker v-model="colourStore.colourThree" /></div>
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色四 :</div>
            <div class="c-colour-manage_colour-input"><l-color-picker v-model="colourStore.colourFour" /></div>
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色五 :</div>
            <div class="c-colour-manage_colour-input"><l-color-picker v-model="colourStore.colourFive" /></div>
          </div>
          <div class="c-colour-manage_colour-bag">
            <div class="c-colour-manage_colour-bag-text">颜色六 :</div>
            <div class="c-colour-manage_colour-input"><l-color-picker v-model="colourStore.colourSix" /></div>
          </div>
        </div>
      </l-modal>
    </div>
    <!-- 新增颜色库和颜色库详情弹窗结束 -->
  </div>
</template>

<script>
export default {
  name: 'ColourManage',
  data() {
    return {
      addColourButtom: false, // 新增颜色库和颜色库详情弹跳框是否显示
      newPage: 1, // 存储当前案例列表页数
      colourStore: { // 新增和详情弹跳窗数据缓存区
        id: '',
        nameZhcn: '', // 中文名称
        nameZhtw: '', // 繁体名称
        nameEn: '', // 英文名称
        colourOne: '#ffffff',
        colourTwo: '#ffffff',
        colourThree: '#ffffff',
        colourFour: '#ffffff',
        colourFive: '#ffffff',
        colourSix: '#ffffff',
      },
      isid: '', // 删除选中列表的id号
      // 列表数据
      tableData: {
        loading: false,
        total: 0,
        page: 1,
        size: 8,
        list: []
      },
      puzzleTypeSelect: -1, // 类型（1、拼图 2、拼字）
      puzzleTypeSelectArr: [
        {
          value: -1,
          name: '全部'
        },
        {
          value: 1,
          name: '拼图'
        },
        {
          value: 2,
          name: '拼字'
        },
      ],
      puzzleType: '',
      puzzleTypeStr: {
        '1': '拼图',
        '2': '拼字'
      }
    }
  },
  mounted() {
    this.showList(1)
  },
  methods: {
    // 颜色库列表展示区
    async showList(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.newPage = page // 存储当前案例列表页数
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('colourManage/colourManageList', {
          page: page,
          size: this.tableData.size,
          puzzleType: this.puzzleTypeSelect === -1 ? '' : this.puzzleTypeSelect,
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.list.forEach((item, index) => {
          item.index = index + 1
        })
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    //   新增颜色库按钮----------------------
    addColour() {
      this.colourStore = this.$options.data().colourStore
      this.puzzleType = ''
      // console.log(this.colourStore)
      this.addColourButtom = true
    },
    //   新增颜色库按钮结束-------------------

    // 新增和详情弹跳框中的确认按钮功能------------
    async colourAddYes() {
      if (!this.puzzleType) {
        this.$message.warn('请选择素材分类')
        return
      }
      // 传入id不为空实现详情修改功能的确认按钮事件
      // 传入的id值为空实现增加功能的确认按钮事件，
      await this.$store.dispatch('colourManage/colourManageSave', {
        id: this.colourStore.id,
        nameZhcn: this.colourStore.nameZhcn,
        nameZhtw: this.colourStore.nameZhtw,
        nameEn: this.colourStore.nameEn,
        color1: this.colourStore.colourOne,
        color2: this.colourStore.colourTwo,
        color3: this.colourStore.colourThree,
        color4: this.colourStore.colourFour,
        color5: this.colourStore.colourFive,
        color6: this.colourStore.colourSix,
        puzzleType: this.puzzleType,
      })
      this.showList(this.newPage)
      this.addColourButtom = false
    },
    // 新增和详情弹跳框中的确认按钮功能结束------------
    // 顺序调整
    async colourSort(id, type) {
      await this.$store.dispatch('colourManage/colourSort', {
        id: id,
        type: type
      })
      this.showList()
    },
    // 顺序调整按钮结束---------------------

    //   案例详情按钮
    colourDetail(list) {
      this.colourStore.id = parseInt(list.id)
      this.colourStore.nameZhcn = list.nameZhcn
      this.colourStore.nameZhtw = list.nameZhtw
      this.colourStore.nameEn = list.nameEn
      this.colourStore.colourOne = list.color1
      this.colourStore.colourTwo = list.color2
      this.colourStore.colourThree = list.color3
      this.colourStore.colourFour = list.color4
      this.colourStore.colourFive = list.color5
      this.colourStore.colourSix = list.color6
      this.puzzleType = +list.puzzleType
      // console.log(this.colourStore)
      this.addColourButtom = true
    },
    // 案例详情按钮结束

    // 删除
    colourRemove(scope) {
      this.isid = scope.id
      this.$confirm({
        title: `是否删除${scope.nameZhcn}`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('colourManage/colourManageRemove', {
            id: scope.id,
          })
          this.showList(1)
        }
      })
    }
  },

}
</script>

<style lang='scss' scoped>
/* 新增按钮  */
.c-colour-manage_add-buttom {
  // margin-bottom: 30px;
}
/* 新增按钮结束  */
//顺序上下按钮
.c-colour-manage_mobile-buttom{
  margin-left: 5px;
}
.c-colour-manage_list-colour-show{
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .c-colour-manage_list-colour-show-son{
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    margin: 3px;
  }
}
/* 新增颜色库弹窗 */
.c-colour-manage_colour-bags{
  width: 370px;
  height: 500px;
  display: flex;
  flex-direction: column;
  // align-content: center;
.c-colour-manage_colour-bag{
  width: 370px;
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .c-colour-manage_colour-bag-text{
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
  }
  .c-colour-manage_colour-input{
      width: 250px;
      height: 30px;
  }

 }
}
</style>
